import { addParentDepPairedHandlers } from './focus';

function registerMenuHandles() {
  for (const el of document.querySelectorAll('[data-menu-select]')) {
    const menu = el.nextElementSibling;
    if (menu) {
      addParentDepPairedHandlers(el, menu);
      el.addEventListener('click', () => {
        if (!menu.classList.toggle('hidden')) {
          // menu.focus();
        }
      });
    }
  }
}

export { registerMenuHandles };
