import './style.css';
import './img/logo.png';
import './img/search_option.svg';
import './img/add_option.svg';
import './img/registry_option.svg';
import './img/avatar_placeholder.svg';
import './img/notification_bell.svg';
import { registerMenuHandles } from './menu';

registerMenuHandles();
