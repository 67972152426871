function addFocusHandlers(el, options) {
  const { elDependent, elBlurTimer, depBlurTimer, onHide, noUserHide } = options;

  const dep = elDependent || el;
  const blurTimer = elBlurTimer || {};

  // useCapture: we want to intercept this event on the el
  // over being dispatched to any eventTarget beneath it and then receiving
  // the event on the bubble up.
  const useCapture = true;
  el.addEventListener('blur', () => {
    blurTimer.timeOut = setTimeout(() => {
      // for some reason, if we set dep hidden
      // in calling code, clicking anywhere will
      // still call the blur handle. To fix this
      // we only send the onHide cb if it's not hidden
      // when this event is received.
      if (!dep.classList.contains('hidden')) {
        if (!noUserHide?.()) {
          dep.classList.add('hidden');
          onHide?.();
        }
      }
    }, 0);
  }, useCapture);

  // when focus happens, a blur also happens just before it.
  // we clear the elTimer to hide blur if this is the case,
  // as it is not really a blur.
  el.addEventListener('focus', () => {
    clearTimeout(blurTimer.timeOut);
    if (depBlurTimer?.timeOut) {
      clearTimeout(depBlurTimer.timeOut);
    }
  }, useCapture);

  el.addEventListener('keydown', (e) => {
    if (e.which === 27) {
      e.preventDefault();
      if (!noUserHide?.()) {
        dep.classList.add('hidden');
        onHide?.();
      }
    }
  });
}

function addParentDepPairedHandlers(parentEl, depEl, options) {
  const elBlurTimer = {};
  const depBlurTimer = {};

  addFocusHandlers(parentEl, {
    ...options,
    elDependent: depEl,
    elBlurTimer,
    depBlurTimer,
  });

  addFocusHandlers(depEl, {
    ...options,
    elBlurTimer: depBlurTimer,
    depBlurTimer: elBlurTimer,
  });
}

export { addFocusHandlers, addParentDepPairedHandlers };
